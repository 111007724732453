<template>
  <div class="addressPopup common-pop">
    <el-dialog :title="'【绑定微信openId】 ' + info.name"
               :visible.sync="visible"
               :close-on-click-modal="false"
               width="1000px">
      <el-table :data="dataList" max-height="700" border v-loading="dataListLoading">
        <el-table-column type="index" width="40" align="center" label="序号"></el-table-column>
        <el-table-column label="选择" width="40" align="center">
          <template slot-scope="scope">
            <el-radio v-model="selectRow"
                      :label="scope.$index"
                      @change.native="selectUser(scope.row)"
                      class="hideLabel">
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column property="openId" label="openId" align="center" min-width="130"></el-table-column>
        <el-table-column property="remark" label="备注名称" align="center"></el-table-column>
        <el-table-column property="driverName" label="已绑定司机" align="center"></el-table-column>
        <el-table-column property="contactPhone" label="联系电话" align="center"></el-table-column>
        <el-table-column property="motorcadeName" label="所属车队" align="center"></el-table-column>
<!--        <el-table-column property="sexDesc" label="性别" width="60" align="center"></el-table-column>-->
<!--        <el-table-column property="city" label="城市" width="110" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            {{scope.row.province + '-' + scope.row.city}}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column property="contactPhone" label="头像" width="120" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <el-image-->
<!--              style="width: 100px; height: 100px"-->
<!--              :src="scope.row.headImgUrl"-->
<!--              fit="scale-down"></el-image>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dataList: [],
      selectRow: null,
      clickRow: null,
      info: null,
      dataListLoading: false,
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0
    }
  },
  methods: {
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 选择
    selectUser (row) {
      this.clickRow = row
    },
    init (row) {
      this.visible = true
      this.selectRow = null
      this.clickRow = null
      this.info = row
      this.dataList = []
      this.getDataList()
    },
    // 获取地址信息
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/wx/followList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'pageSize': this.pageSize
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 提交地址
    submit () {
      if (this.clickRow === null || this.clickRow === undefined) {
        this.$message.error('请选择一个进行操作')
        return false
      }
      this.$http({
        url: this.$http.adornUrl('/wx/bindOpenId'),
        method: 'post',
        data: this.$http.adornData({
          'id': this.info.id,
          'wxOpenId': this.clickRow.openId
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '绑定成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    }
  }
}
</script>
<style lang="less">
.addressPopup {
  .hideLabel {
    .el-radio__label {
      display: none
    }
  }

  thead th .cell {
    height: 32px;
    line-height: 32px;
  }

  .shortInput {
    width: 140px;
  }

  .searchArea {
    background: #f6f6f6;
    padding-top: 5px;
    padding-left: 5px;
  }

  .el-dialog__header {
    padding: 13px 18px 0px;
  }

  .el-button {
    padding: 6px 10px;
  }

  .el-dialog__body {
    padding: 12px 20px;
  }

  .el-table--medium td, .el-table--medium th {
    padding: 3px 0;
  }
}
</style>
